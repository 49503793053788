// Colours 

$red: red;
$medium-red: #d90000;
$medium-red-two: #c00000;
$dark-red: #580000;
$dark-red-two: #3f0000;
$black: #151515;
$white: white;

// Allias 

$primary: $red !default;
$secondary: $dark-red;
$tertiary: $dark-red-two;
$fourth: $medium-red-two;
$background-primary: $black;
$text-primary: $white;
$text-secondary: $black;
$header-primary: $medium-red;