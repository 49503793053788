@import '../../scss/_fonts.scss';
@import '../../scss/_colours.scss';

.Subheader {
  font: $subheader-text-small;
  color: $text-primary;
}

@media (min-width: 650px) {
  .Subheader {
    font: $subheader-text;
    line-height: 100%;
  }
}

@media (min-width: 1200px) {
  .Subheader {
    font: $subheader-text-large;
    line-height: 100%;
  }
}