@import '../../scss/_colours.scss';

.NavigationItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding-bottom: 5%;
}

.NavigationItem a {
  color: $text-primary; 
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.NavigationItem a:hover, 
.NavigationItem a:active,
.NavigationItem a.active {
  color: $primary;
}

@media (min-width: 500px) {
  .NavigationItem {
      margin-left: 30px;
      display: flex;
      height: 90%;
      width: auto;
      align-items: center;
  }
  
  .NavigationItem a {
      color: $text-primary;
      height: 90%;
      padding: 10px;
  }
  
  .NavigationItem a:hover {
    cursor: pointer;
  }
  
  .NavigationItem a:hover, 
  .NavigationItem a:active,
  .NavigationItem a.active {
      border-bottom: solid $primary;
      color: $primary;
      height: 90%;
      padding: 10px;
  }   
}