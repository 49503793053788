@import '../../scss/_colours.scss';

.NavigationMenu {
  background-color: $secondary;
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 100;
}

.NavigationMenu nav {
  height: 100%;
}

.NavigationMenuLogo {
  height: 80%;
}


@media (max-width: 499px) {
  .NavigationMenuDesktopOnly {
    display: none;
  }
}