//For the footer to stick, find the container and give it a height:100vh;

@import '../../scss/_colours.scss';
@import '../../scss/_fonts.scss';

.Footer img {
  max-width: 100vw;
  margin-top: auto;
}

.Footer p {
  font-size: $footer-small;
  margin: 0;
  padding-bottom: 6px;
}

.Footer {
  background-color: $secondary;
}

.Footer a {
  text-decoration: none;
  color: $text-primary;
}

@media (min-width: 850px) {
  .Footer p {
    font-size: $footer;
  }
}