.Fire {
  position: absolute;
  animation: fadeOut 5s forwards;
  background-color: black;
  top: 40%;
}

.Fire1, .Fire2, .Fire3 {
  position: absolute;
  animation-iteration-count: 3;
  width: 100%;
  transform: scale(1.5);
}

.Fire1 {
  z-index: -3;
  animation: fire1 3s linear forwards;
}

.Fire2 {
  z-index: -4;
  animation: fire2 3s linear forwards;
}

.Fire3 {
  z-index: -5;
  animation: fire3 3s linear forwards;
}

.Fire4 {
  animation: fire4 3s linear forwards;
  animation-iteration-count: 3;
  width: 100%;
  transform: scale(1.5);
}

@keyframes fadeOut {
  0% {
    opacity: 0.2;
  }

  60% {
   opacity: 1; 
  }

  100% {
    opacity: 0;
  }
}

@keyframes fire1 {
  0% {
    opacity: 0.2;
  }
  
  10% {
    opacity: 0.4;
  }

  20% {
    opacity: 0.6;
  }

  30% {
    opacity: 0.4;
  }

  40% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.4;
  }

  60% {
    opacity: 0.6;
  }

  70% {
    opacity: 0.4;
  }

  80% {
    opacity: 0.2;
  }

  90% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes fire2 {
  0% {
    opacity: 0.4;
  }
  
  10% {
    opacity: 0.2;
  }

  20% {
    opacity: 0.8;
  }

  30% {
    opacity: 0.6;
  }

  40% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.2;
  }

  60% {
    opacity: 0.8;
  }

  70% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.4;
  }

  90% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes fire3 {
  0% {
    opacity: 0.6;
  }
  
  10% {
    opacity: 0.2;
  }

  20% {
    opacity: 0.4;
  }

  30% {
    opacity: 0.8;
  }

  40% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.2;
  }

  60% {
    opacity: 0.4;
  }

  70% {
    opacity: 0.8;
  }

  80% {
    opacity: 0.6;
  }

  90% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.6;
  }
}

@keyframes fire4 {
  0% {
    opacity: 0.8;
  }
  
  10% {
    opacity: 0.2;
  }

  20% {
    opacity: 0.4;
  }

  30% {
    opacity: 0.6;
  }

  40% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.2;
  }

  60% {
    opacity: 0.4;
  }

  70% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.8;
  }

  90% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.8;
  }
}