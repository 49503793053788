.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.FadeIn {
  height: 100vh;
  animation: fadeIn 7s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}